<template>
  <div>
    <base-button
      class="notification"
      type="info"
      icon
      size="sm"
      @click="openSubscriptionModal"
    >
      <span class="btn-inner--icon">
        <i class="fa-light fa-bell"></i>
      </span>
    </base-button>
    <modal :show="showModal" modal-classes="modal-secondary text-left">
      <h2 class="text-center">
        {{ $t("NOTIFICATIONS.SUBSCRIBE_TO_NOTIFICATIONS") }}
      </h2>
      <div class="row">
        <div
          v-for="(event, key) in events"
          :key="key"
          class="col-12 row notification-event"
        >
          <base-checkbox v-model="subscriptionsModel[key]">
            {{ event }}
          </base-checkbox>
        </div>
      </div>
      <template slot="footer">
        <button
          type="button"
          class="btn btn-elite black"
          @click="closeModal"
          :disabled="loading"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
        <button
          type="submit"
          class="btn ml-auto btn-elite save"
          @click="save"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("NOTIFICATIONS.SAVE") }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { Option, Select } from "element-ui";

export default {
  name: "notification-subscription",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    objectType: {
      type: String,
      description: "Object type of the notification subscription",
    },
    objectId: {
      type: String | Number,
      description: "Object id of the notification subscription",
    },
    events: {
      type: Object,
      description: "Object format {EVENT_VALUE: EVENT_NAME}",
    },
  },

  data() {
    return {
      subscribedEvents: [],
      subscriptionsModel: {},
      showModal: false,
      loading: false,
    };
  },

  setup() {},

  created() {},

  methods: {
    async openSubscriptionModal() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      let params = {
        sort: "event",
        filter: {
          object_type: this.objectType,
          object_id: this.objectId,
        },
        page: {
          number: 1,
          size: 999,
        },
        include: "",
      };
      try {
        await this.$store.dispatch("notificationSubscriptions/list", params);
        this.subscribedEvents =
          this.$store.getters["notificationSubscriptions/list"];
        for (const subscription of this.subscribedEvents) {
          this.subscriptionsModel[subscription.event] = true;
        }
        for (const event of Object.keys(this.events)) {
          if (!Object.keys(this.subscriptionsModel).includes(event)) {
            this.subscriptionsModel[event] = false;
          }
        }
        this.showModal = true;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      swal.close();
    },

    closeModal() {
      this.showModal = false;
    },

    async save() {
      this.loading = true;
      try {
        const eventsSubscriptions = [];
        for (const event of Object.keys(this.subscriptionsModel)) {
          if (this.subscriptionsModel[event]) {
            if (!this.subscribedEvents.find((item) => item.event === event)) {
              const subscriptionData = {
                type: "notification-subscriptions",
                event: event,
                object_type: this.objectType,
                object_id: this.objectId,
              };
              await this.$store.dispatch(
                "notificationSubscriptions/add",
                subscriptionData
              );
            }
            eventsSubscriptions.push(event);
          }
        }

        for (const subscription of this.subscribedEvents) {
          if (!eventsSubscriptions.includes(subscription.event)) {
            await this.$store.dispatch(
              "notificationSubscriptions/destroy",
              subscription.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }

      this.$notify({
        type: "success",
        timeout: 3000,
        message: this.$t("NOTIFICATIONS.SUBSCRIPTIONS_SAVED"),
      });
      this.loading = false;
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
.base-button.notification {
  box-shadow: none;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  //color: #ffeb00;
  border-radius: 8px;
  border: solid 1px #000;
  min-width: 32px;
  min-height: 32px;
  &:hover {
    transform: none;
    box-shadow: none;
    background-color: #000;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    .btn-inner--icon {
      i {
        color: #fff;
      }
    }
  }
  .btn-inner--icon {
    line-height: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    i {
      font-size: 18px;
      color: #000;
      padding: 0;
      position: relative;
      top: 0px;
    }
  }
  &.btn-elite {
    width: auto;
    background-color: #ffeb00;
    color: #000;
  }
}

.modal {
  &-dialog {
    .modal-content {
      background-color: #fff;

      .modal-body {
        .row {
          margin: 0;
          .custom-checkbox .custom-control-label {
            margin: 5px 0;
            padding: 0 10px;
            font-size: 15px;
            &:before,
            &:after {
              left: -20px;
              top: 2px;
            }
          }
          .custom-control-input:checked ~ .custom-control-label::before {
            background-color: #000;
          }
          .custom-control-input:checked ~ .custom-control-label::after {
            background-image: none;
            content: "\f00c";
            font-family: "Font Awesome\5 Pro";
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            color: var(--primary-color);
            top: 2px;
            left: -20px;
          }
        }
      }

      .modal-footer {
        border-top: solid 1px #e6e6e6;
        button {
          &.btn {
            margin: 0;
            border: none;
            box-shadow: none;
            opacity: 1;
            &:hover {
              box-shadow: none;
              transform: none;
            }
            &.close {
              background-color: #000;
            }
            &.save {
              background-color: #ffeb00;
              color: #000;
            }
            &.black {
              background-color: #000;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.modal-body .notification-event .custom-control.custom-checkbox label {
  font-size: 1.1rem;
  margin: 20px 0px;
  font-weight: 550;
}
</style>
