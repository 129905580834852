<template>
  <el-select
    v-model="packageModel"
    @change="packageChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.PACKAGE')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getPackages"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL_PACKAGES')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="pack in packages"
      :key="pack.id"
      :value="pack.id"
      :label="`${pack.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "package-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    pack: {
      type: String,
      default: null,
      description: "Package id",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
  },

  data() {
    return {
      packageModel: this.pack,
      packages: {},
    };
  },

  setup() {},

  created() {
    this.getPackages(null, this.pack);
  },

  methods: {
    async getPackages(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { with_ids: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterReseller) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: this.filterReseller,
            },
          };
        }

        await this.$store.dispatch("packages/list", params);
        const packagesArr = await this.$store.getters["packages/list"];
        this.packages = {};
        packagesArr.forEach((pack) => {
          this.packages[pack.id] = pack;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    packageChanged(packId) {
      const pack = Object.values(this.packages).find(
        (item) => item.id === packId
      );
      this.$emit("packageChanged", packId, pack);
    },
  },

  watch: {
    pack(pack) {
      if (pack) {
        this.packageModel = pack;
        if (pack !== this.packageModel) {
          this.getPackages(null, pack);
        }
      } else {
        this.packageModel = null;
      }
    },
    filterReseller(filterReseller) {
      this.getPackages();
    },
  },
};
</script>
